.home {
  max-width: 800px;
  border: var(--border);
  border-radius: var(--border-radius);
  margin: 0 auto;
}

.banks-btn {
  display: flex;
  justify-content: center;
}

.privat button {
  display: flex;
  height: 40px;
  margin: 10px;
  padding: 10px;
  border: solid 1px black;
  border-radius: 10px;
  font-size: large;
  align-items: center;
  color: var(--title-color)
}

.monobank {
  display: flex;
  justify-content: center;

}

.monobank a {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 20px;
  background-color: black;
  color: white;
  margin: 10px;
  padding: 10px;
  border-radius: var(--border-radius)
}