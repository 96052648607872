.bank {
  margin: 10px;
  padding: 20px;
  border: var(--border);
  border-radius: var(--border-radius);
  color: rgb(50, 150, 150);
}

.bank p {
  padding: 10px 0;
}