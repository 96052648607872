header {
  display: flex;
  justify-content: center;
  padding: 20px 50px;
  background-color: var(--bg-color);
  border-radius: 5px;
}

.logo {
  height: 100px;
}

.title {
  margin-left: 100px;
  padding-top: 30px;
}

h1 {
  color: var(--title-color);
}

@media screen and (max-width: 600px) {
  .title {
    margin-left: 70px;
    padding-top: 0;
  }
}