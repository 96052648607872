.iban {
  display: flex;
}

.iban button {
  margin-left: 20px;
  border: none;
  background: none;
}

.iban button:hover {
  cursor: pointer;
  scale: 1.1;
}

.iban img {
  width: 15px;
}