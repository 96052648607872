footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  height: 80px;
  background-color: var(--bg-color);
  color: var(--title-color);
  font-size: large;

}

.foot-link {
  text-decoration: none;
  color: var(--title-color);
}

.foot-link:hover {
  color: rgb(1, 25, 27);
}

footer img {
  padding-right: 10px;
}

.facebook {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 800px) {
  footer p {
    display: none;
  }
}